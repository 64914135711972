.App {
  text-align: center;
  height: 100vh;
  font-family: Andale Mono, AndaleMono, monospace;
  background: #F1E3D3;
  background-image: linear-gradient(rgba(157, 157, 157, 0.2) .1em, transparent .1em), linear-gradient(90deg, rgba(157, 157, 157, 0.2) .1em, transparent .1em);
  background-size: 2em 2em;
}

.companyLogo {
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: auto;
  display: block;
}

.menuContainer {
  position: absolute;
  bottom: 10%; 
  left: 10%;
  border-style: solid;
  border-width: thin;
  border-radius: 5px;
  background-color: rgba(153, 193, 185, 0.5);
  box-shadow: 5px 5px;
}

.contentContainer {
  margin: 50px;
  min-width: 200px;
}

.contentHeader {
  font-weight: 900;
  text-align: left;
  margin-top: 10px;
}

.contentDescription {
  text-align: left;
  font-style: italic;
}

.projectsInsert {
  margin: 10px;
  min-height: 200px;
}

.projectsName {
  text-decoration: underline;
  color: #4A6670;
  margin-bottom: 10px;
}

.projectsImage {
  width: 100px;
  height: 75px;
  float: left;
}

.projectsSummary {
  color: black;
  float: right;
  font-style: italic;
  max-width: 175px;
}

.contentBody {
  text-align: left;
  margin-top: 15px;
}

.learningsHeader {
  text-align: left;
  margin-left: 10px;
  margin-top: 10px;
  font-weight: 900;
}

.learningsText {
  text-align: left;
  margin-left: 10px;
}

.menuItem {
  margin: 30px;
}

.menuItem:hover {
  color: white;
  text-decoration: underline;
}

.infoContainer {
  position: absolute;
  top: 20%;
  left: 40%;
  border-style: solid;
  border-width: thin;
  border-radius: 5px;
  width: 50%;
  max-height: 70%;
  max-width: 800px;
  background-color: rgba(153, 193, 185, 0.5);
  overflow-y: auto;
  scrollbar-color: rgba(255, 255, 255, 0.5) rgba(153, 193, 185, 0.5);
  box-shadow: 5px 5px;
}

@media (max-width: 1250px) {
  .projectsImage {
    all:unset;
  }

  .projectsSummary {
    all: unset;
    font-style: italic;
  }

}
@media (max-width: 800px) {
  .menuContainer {
    display: flex;
    flex-direction: column;
    width: 75%;
    max-height: 20%;
    overflow-y: scroll;
    bottom: 3%;
    min-width: 300px;
  }

  .projectsImage {
    all:unset;
  }

  .projectsSummary {
    all: unset;
    font-style: italic;
  }


  .menuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 5px 10px 5px;
  }

  .infoContainer {
    position: fixed;
    height: auto;
    max-height: 70%;
    top: 5%;
    left: 10%;
    width: 75%;
    min-width: 300px;
    overflow-x: hidden;
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    margin-bottom: 10px; 
  }

  .logoImage {
    max-height: 50px;
    max-width: 50px;
    width: 50px;
    /* max-height: 50px; */
  }

  .menuItemsContainer {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
